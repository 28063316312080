import React, { useEffect } from "react";
import { useLocale } from "@transifex/react";

import "./Footer.css";

const Footer = () => {
  const locale = useLocale();

  useEffect(() => {
    const config = {
      containerId: "ch-incident-status", // Optional - default: ch-incident-status
      theme: "dark", // Optional - default: light
      language: locale.slice(0, 2), // Optional - default: browser language (de, en, fr, it)
      groups: "access", // Optional - default: All
      placement: "footer", // Optional - default: footer
      apiKey: "bfeb6c52e219b6da2a85b60c25004880", // <-- On last pass under 'Instatus Subscriptions'. It's the key in the notes.
    };

    window?.ChIncidentWidget?.initWidget &&
      window.ChIncidentWidget.initWidget(config);
  }, [locale]);

  return (
    <div id="footer" className="p-3 bg-black text-light">
      <div className="row">
        <div
          id="ch-incident-status"
          className="col-lg-5 d-lg-flex d-inline-flex justify-content-center justify-content-lg-end pe-0"
        ></div>
        <div className="address d-inline-flex col-lg-7 justify-content-center justify-content-lg-start align-items-center ps-0">
          &copy; {new Date().getFullYear()} By Starticket AG
          <span className="d-none d-sm-block">
            <span className="px-4">|</span>Thurgauerstrasse 80, 8050 Z&uuml;rich
          </span>
        </div>
        <div className="address d-inline-flex col-lg-6 justify-content-center justify-content-lg-start align-items-center ps-0 d-flex d-sm-none my-1">
          Thurgauerstrasse 80, 8050 Z&uuml;rich
        </div>
      </div>
      <div className="text-center p-1 d-none">
        <span className="sub">API Build</span> 12354&nbsp;|&nbsp;
        <span className="sub">Admin Build</span> 6789
      </div>
    </div>
  );
};

export default Footer;
