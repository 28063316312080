import React, { Component } from "react";
import moment from "moment";
import { ValidatorService } from "./services";
import { SelectService } from "../../services";
import { Redirect } from "react-router-dom";
import { T, useT } from "@transifex/react";
import { TinyCards } from "./components/TinyCards";
import { ShowService } from "../../../../services/api/Show";
import { TicketService } from "../../../../services/api/Ticket";
import { LoadingScreen } from "../../../../components/Loading";
import { Alert, AlertTypeSuccess } from "../../../../themes/default/Alert";
import { ErrorService } from "../../../../services/utils/Error";
import { DatePickerInput } from "../../../../components/DatePicker";
import { Dialog } from "../../../../themes/default/Dialog";
import { DialogError } from "../../../../themes/default/Dialog";
import { DateTimeService } from "../../../../services/utils/DateTime";
import { ResponseService } from "../../../../services/utils/Response";
import { Input } from "../../../../themes/default/Form/components/Input";
import {
  Button,
  ButtonSize,
} from "../../../../themes/default/Form/components/Button";
import { SceneTitle } from "../../../../themes/default/Title/components/Scene";
import { Checkbox } from "../../../../themes/default/Form/components/Checkbox";
import { MultiSelect } from "../../../../themes/default/Form/components/MultiSelect";

import IconInformation from "./images/ico-information.svg";
import ShowImage from "../../images/ico-no-image.jpg";

import "./style.css";
import { DownloadButton } from "../../../../themes/default/Form/components/DownloadButton";

/**
 * @TODO Missing tooltip component
 * @TODO Missing form fields validation
 * @class ./scenes/Details/Details
 */
class Details extends Component {
  /**
   * @type {Object}
   */
  dateTimeService;

  /**
   * @type {Object}
   */
  validatorService;

  /**
   * @type {Object}
   */
  responseService;

  /**
   * @type {Object}
   */
  selectService;

  /**
   * @type {Object}
   */
  errorService;

  state = {
    show: {},
    allowReentryAfter: false,
    allowReentryAfterValue: "",
    unlimitedTicketUsage: false,
    ignoreTimeSlots: false,
    useTimeSlots: false,
    unlimitedTicketUsageValue: 1,
    showLoadingScreen: false,
    totalTicketsCount: 0,
    multiSelectData: [],
    showSuccessMessage: false,
    showConfirmationDialog: false,
    redirectToShowManagementPage: false,
    showErrorDialog: false,
    errorMessages: [],
    formErrors: [],
    doorsOpen: "",
    doorsOpenAtDateTime: "",
    isBeginDateChanged: false,
    doorsOpenAtTime: "",
  };

  /**
   * @override
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    this.showService = new ShowService();
    this.ticketService = new TicketService();
    this.dateTimeService = new DateTimeService();
    this.responseService = new ResponseService();
    this.selectService = new SelectService();
    this.validatorService = new ValidatorService();
    this.errorService = new ErrorService();
  }

  /**
   * @override
   */
  componentDidMount() {
    let showId = this.props.match.params.id;

    this.setState({
      showLoadingScreen: true,
    });

    this.getShow(showId);

    this.ticketService.getAllForShow(showId).then((response) => {
      if (response.status === 200) {
        this.setState({
          totalTicketsCount: response.data.totalResultCount,
        });
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
        });
      }
    });
  }

  getShow = (showId) => {
    this.showService.getOne(showId).then((response) => {
      if (response.status === 200) {
        if (JSON.stringify(this.state.show) !== JSON.stringify(response.data)) {
          this.setState({
            show: response.data,
            allowReentryAfter: !!response.data.allowReentryAfter,
            allowReentryAfterValue: response.data.allowReentryAfter ?? "",
            unlimitedTicketUsage: !!!response.data.ticketUsageLimit,
            multiSelectData: this.selectService.getBarcodeOptions(
              response.data.barcodeSymbologies
            ),
            ...(!!response.data.ticketUsageLimit && {
              unlimitedTicketUsageValue: response.data.ticketUsageLimit,
            }),
          });
        }
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
        });
      }

      this.setState({
        showLoadingScreen: false,
      });
    });
  };

  /**
   * Updates show's property.
   *
   * @param {String} property
   * @param {String} value
   */
  updateShowProperty = (property, value) => {
    if (this.state.show[property] !== value) {
      let show = { ...this.state.show };
      show[property] = value;

      this.setState({
        show: show,
      });

      /**
       * if the formErrors is date, check the begin date if before the end date + add an error to the formErrors
       */
      if (property === "begin") {
        const convertedBeginDate = moment(
          value,
          `${value.length === 16 ? "DD-MM-YYYY HH:mm" : "YYYY-MM-DDTHH:mm:ssZ"}`
        ).toISOString();
        const convertedEndDate = moment(
          show["end"],
          `${
            show["end"].length === 16
              ? "DD-MM-YYYY HH:mm"
              : "YYYY-MM-DDTHH:mm:ssZ"
          }`
        ).toISOString();
        const isBeginDateBeforeEndDate =
          moment(convertedBeginDate).isBefore(convertedEndDate);

        if (!isBeginDateBeforeEndDate) {
          const newFormErrors = this.state.formErrors;
          newFormErrors["begin"] =
            "The BEGIN date cannot be later than the END date";
          !isBeginDateBeforeEndDate &&
            this.setState({ formErrors: newFormErrors });
          this.isValid(value, "end");
        }
      }

      if (property === "end") {
        const convertedBeginDate = moment(
          show["begin"],
          `${
            show["begin"].length === 16
              ? "DD-MM-YYYY HH:mm"
              : "YYYY-MM-DDTHH:mm:ssZ"
          }`
        ).toISOString();
        const convertedEndDate = moment(
          value,
          `${value.length === 16 ? "DD-MM-YYYY HH:mm" : "YYYY-MM-DDTHH:mm:ssZ"}`
        ).toISOString();
        const isBeginDateBeforeEndDate =
          moment(convertedBeginDate).isBefore(convertedEndDate);

        if (!isBeginDateBeforeEndDate) {
          const newFormErrors = this.state.formErrors;

          newFormErrors["end"] = "The END date cannot be before the BEGIN date";

          !isBeginDateBeforeEndDate &&
            this.setState({ formErrors: newFormErrors });
          this.isValid(value, "begin");
        }
      }
    }
  };

  /**
   * Gets show name input value.
   *
   * @param {String} value
   */
  getNameInputValue = (value) => this.updateShowProperty("name", value);

  /**
   * Gets print title input value.
   *
   * @param {String} value
   */
  getPrintTitleValue = (value) => this.updateShowProperty("printTitle", value);

  /**
   * Gets subtitle input value.
   *
   * @param {String} value
   */
  getSubtitleValue = (value) => this.updateShowProperty("subTitle", value);

  /**
   * Gets begin input value.
   *
   * @param {String} value
   */
  getBeginValue = (value) => {
    this.isValid(value, "begin");
    if (this.state.doorsOpen === "") {
      this.setState({
        doorsOpen: this.getDoorsOpenTime(),
      });
    }

    this.updateShowProperty("begin", value);
  };

  /**
   * Gets end input value.
   *
   * @param {String} value
   */
  getEndValue = (value) => {
    this.isValid(value, "end");
    this.updateShowProperty("end", value);
  };

  /**
   * Gets doors open at input value.
   *
   * @param {String} value
   */
  getDoorsOpenAtValue = (value) => {
    let offset = this.dateTimeService.getDSTOffset(this.state.show.begin);
    let valueUTC = moment(value, "HH:mm")
      .subtract(offset, "hours")
      .format("HH:mm:ss");

    if (valueUTC !== this.state.show.doorsOpen) {
      this.updateShowProperty("doorsOpen", value);
    }
  };

  /**
   * Gets provider input value.
   *
   * @param {String} value
   */
  getProviderValue = (value) => this.updateShowProperty("providerName", value);

  /**
   * Gets external show id input value.
   *
   * @param {String} value
   */
  getExternalShowIdValue = (value) =>
    this.updateShowProperty("externalId", value);

  /**
   * Gets allow reentry after checkbox value.
   *
   * @param {Boolean} value
   */
  getAllowReentryAfterCheckboxValue = (value) => {
    let formErrors = this.state.formErrors;

    if (value) {
      this.updateShowProperty(
        "allowReentryAfter",
        this.state.allowReentryAfterValue
      );
      if (!this.state.show.allowReentryAfter) {
        this.isValid(this.state.allowReentryAfterValue, "allow-reenter-after");
      }
    } else {
      delete formErrors["allow-reenter-after"];
      this.updateShowProperty("allowReentryAfter", null);
    }
    this.setState({
      allowReentryAfter: value,
      formErrors: formErrors,
    });
  };

  /**
   * Gets allow reentry after input value.
   *
   * @param {String} value
   */
  getAllowReentryAfterInputValue = (value) => {
    this.updateShowProperty("allowReentryAfter", value);
    if (this.state.show.allowReentryAfter !== value) {
      this.setState({
        allowReentryAfterValue: value,
      });
    }
  };

  /**
   * Updates show's grace period value.
   *
   * @param {String} value
   */
  getTimeslotsGracePeriod = (value) =>
    this.updateShowProperty("timeSlotGracePeriod", value);

  /**
   * Gets tickets usage limit select value.
   *
   * @param {String} value
   */
  getTicketUsageLimitSelectValue = (value) => {
    if (
      (this.state.show.ticketUsageLimit !== value &&
        !this.state.unlimitedTicketUsageValue) ||
      (this.state.show.ticketUsageLimit !== value &&
        !this.state.unlimitedTicketUsage)
    ) {
      this.updateShowProperty("ticketUsageLimit", value);
      if (value) {
        this.setState({
          unlimitedTicketUsageValue: value,
        });
      } else {
        this.setState({
          unlimitedTicketUsageValue: 1,
        });
      }
    }
  };

  /**
   * Gets unlimited checkbox value.
   *
   * @param {Boolean} value
   */
  getUnlimitedCheckboxValue = (value) => {
    if (!value) {
      this.updateShowProperty(
        "ticketUsageLimit",
        !!this.state.unlimitedTicketUsageValue
          ? this.state.unlimitedTicketUsageValue
          : 1
      );
    } else {
      this.updateShowProperty("ticketUsageLimit", "0");
    }
    this.setState({
      unlimitedTicketUsage: value,
    });
  };

  /**
   * Gets the box office mode flag
   */
  getBoxOfficeModeValue = (value) =>
    this.updateShowProperty("boxOfficeMode", value);

  /**
   * Gets tickets timeslot value.
   */
  getTicketsTimeslotValue = () => {};

  /**
   * Gets ignore timeslots value.
   */
  getIgnoreTimeslotsValue = (value) => {
    this.updateShowProperty("ignoreTimeSlots", value);
    if (value) {
      this.setState({
        ignoreTimeSlots: true,
      });
    } else {
      this.setState({
        ignoreTimeSlots: false,
      });
    }
  };

  /**
   * Check if there is some form errors.
   *
   * @returns {boolean}
   */
  isValidShowFields = () => {
    return Object.keys(this.state.formErrors).length === 0;
  };

  isDoorOpenAtTimeUTC = () => {
    let doorsOpenTime = this.state.show.doorsOpen;

    let isDoorOpenTimeUTC = false;

    if (doorsOpenTime && doorsOpenTime.split(":").length === 3) {
      isDoorOpenTimeUTC = true;
    }

    return isDoorOpenTimeUTC;
  };

  /**
   * Returns doors open time in UTC format.
   *
   * @return {String}
   */
  getDoorsOpenUTCTime = () => {
    let doorsOpenAtTime;
    let isShowBeginISOFormat = this.dateTimeService.isDateTimeISOFormat(
      this.state.show.begin
    );
    let isDoorOpenUTC = this.isDoorOpenAtTimeUTC();
    let oldDoorsOpen =
      !isShowBeginISOFormat && isDoorOpenUTC
        ? this.state.doorsOpen
        : this.state.show.doorsOpen;

    if (isShowBeginISOFormat && isDoorOpenUTC) {
      doorsOpenAtTime = oldDoorsOpen;
    } else {
      doorsOpenAtTime = moment(oldDoorsOpen, "HH:mm:ss")
        .subtract(0, "hours")
        .format("HH:mm");
    }

    if (doorsOpenAtTime === "Invalid date") {
      return "";
    }

    return doorsOpenAtTime;
  };

  /**
   * Updates show according to local state show object value.
   */
  updateShow = () => {
    if (this.isValidShowFields()) {
      this.setState({
        showLoadingScreen: true,
      });

      let show = {
        ...this.state.show,
        begin: this.dateTimeService.getUTCTime(this.state.show.begin),
        end: this.dateTimeService.getUTCTime(this.state.show.end),
        barcodeSymbologies: this.selectService.barcodeOptionsToTitleList(
          this.state.multiSelectData
        ),
        doorsOpen: this.getDoorsOpenUTCTime(),
      };
      this.dateTimeService.getDSTOffset(this.state.show.begin);
      this.showService.updateOne(this.state.show.id, show).then((response) => {
        if (response.status === 204) {
          this.setState({
            showSuccessMessage: true,
          });
        } else {
          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(response.data),
          });
        }
        this.setState({
          showLoadingScreen: false,
        });
      });
    } else {
      this.setState({
        showErrorDialog: true,
        errorMessages: ["Form is not valid"],
      });
    }

    if (
      this.state.multiSelectData.filter((barcode) => barcode.selected)
        .length === 0
    ) {
      this.setState({
        multiSelectData: this.selectService.getBarcodeOptions(),
      });
    }
  };

  /**
   * Deletes show according to show id and redirects user to show management page.
   */
  deleteShow = () => {
    this.setState({
      showLoadingScreen: true,
    });
    this.showService.deleteOne(this.state.show.id).then((response) => {
      if (response.status === 204) {
        this.setState({
          showConfirmationDialog: false,
          redirectToShowManagementPage: true,
        });
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
        });
      }
      this.setState({
        showLoadingScreen: false,
      });
    });
  };

  /**
   * Updates state and opens delete show confirmation dialog.
   */
  openConfirmationDialog = () => {
    this.setState({
      showConfirmationDialog: true,
    });
  };

  /**
   * Updates state and closes delete show confirmation dialog.
   */
  closeConfirmationDialog = () => {
    this.setState({
      showConfirmationDialog: false,
    });
  };

  /**
   * Updates state and closes error dialog.
   */
  closeErrorDialog = () => {
    this.setState({
      showErrorDialog: false,
    });
  };

  /**
   * Checks filed value validation and returns boolean.
   *
   * @param value
   * @param name
   *
   * @returns {Boolean}
   */
  isValid = (value, name) => {
    let errors = this.validatorService.validateField(value, name);

    let isValid = errors.length === 0;
    let formErrors = this.state.formErrors;
    delete formErrors[name];

    if (!isValid) {
      formErrors[name] = errors;
    }

    this.setState({
      formErrors: formErrors,
    });

    return isValid;
  };

  /**
   * Returns doors open time in UTC format based on begin show date.
   *
   * @return {String}
   */
  getDoorsOpenTime = () => {
    if (this.dateTimeService.isDateTimeISOFormat(this.state.show.begin)) {
      if (!this.state.show.doorsOpen) {
        return "";
      }
      return this.getDoorsOpenAtDateTime().split(" ")[1];
    }
  };

  /**
   * Returns local doors open time.
   *
   * @returns {String}
   */
  getDoorsOpenAtDateTime = () => {
    // Reformat date from "2021-11-10T09:00:00Z" to "2021-11-10"
    let formattedBeginDate = this.dateTimeService.getFormattedUTCDate(
      this.state.show.begin
    );
    let doorsOpenAtDateTime =
      formattedBeginDate + " " + this.state.show.doorsOpen;
    // Reformat time from "2021-11-10 10:00:00" to "2021-11-10 10:00"
    let doorsOpenAtFormattedDateTime =
      this.dateTimeService.getFormattedUTCDateTime(doorsOpenAtDateTime);

    return doorsOpenAtFormattedDateTime;
  };

  /**
   * Handles MultiSelect dropdown list toggle.
   *
   * @param {Object} item
   */
  handleToggleMultiSelect = (item) => {
    const multiSelectData = [...this.state.multiSelectData];
    const index = multiSelectData.indexOf(item);
    multiSelectData[index] = { ...multiSelectData[index] };
    multiSelectData[index].selected = !multiSelectData[index].selected;

    this.setState({
      multiSelectData,
    });
  };

  importBarcode = () => {
    const id = this.state.show.id;
    this.setState({ showLoadingScreen: true });

    this.showService.importBarcodes(id).then((response) => {
      this.setState({ showLoadingScreen: false });
      if (response.status === 204) this.getShow(id);
    });
  };

  /**
   * @returns {XML}
   */
  render() {
    const scanLogsFilename = `scanlogs-${moment(this.state.show.begin).format(
      "yyyyMMDDHHmm"
    )}-${this.state.show.name}-${this.state.show.externalId}.csv`;
    
    return (
      <div className="ShowDetails">
        <Tite name={this.state.show.name} />

        {this.state.showSuccessMessage && (
          <Alert type={AlertTypeSuccess}>
            <T
              _str="Show {showName} has been successfully updated!"
              showName={this.state.show.name}
            />
          </Alert>
        )}

        {!this.props.isMobileView && (
          <p className="mb-4">
            <T _str="See and edit show details. Some fields are inactive for shows created by integrated ticketing systems. 'Allow re-entry' after and 'ticket usage limit' may be overwritten by providers also supporting these features like Starticket classic." />
          </p>
        )}

        {this.state.show.id && (
          <div className="ShowPanel d-flex flex-column gap-4">
            <div className="d-flex align-items-start gap-4">
              <div className="col-lg-8 p-0">
                <div className="d-flex flex-column gap-4 default-panel">
                  <div className="row">
                    <EditForm
                      show={this.state.show}
                      formErrors={this.state.formErrors}
                      dateTimeService={this.dateTimeService}
                      multiSelectData={this.state.multiSelectData}
                      allowReentryAfter={this.state.allowReentryAfter}
                      allowReentryAfterValue={this.state.allowReentryAfterValue}
                      unlimitedTicketUsage={this.state.unlimitedTicketUsage}
                      unlimitedTicketUsageValue={
                        this.state.unlimitedTicketUsageValue
                      }
                      isValid={this.isValid}
                      errorService={this.errorService}
                      getEndValue={this.getEndValue}
                      getBeginValue={this.getBeginValue}
                      getSubtitleValue={this.getSubtitleValue}
                      getDoorsOpenTime={this.getDoorsOpenTime}
                      getProviderValue={this.getProviderValue}
                      getNameInputValue={this.getNameInputValue}
                      getPrintTitleValue={this.getPrintTitleValue}
                      getDoorsOpenAtValue={this.getDoorsOpenAtValue}
                      getExternalShowIdValue={this.getExternalShowIdValue}
                      handleToggleMultiSelect={this.handleToggleMultiSelect}
                      getIgnoreTimeslotsValue={this.getIgnoreTimeslotsValue}
                      getTimeslotsGracePeriod={this.getTimeslotsGracePeriod}
                      getUnlimitedCheckboxValue={this.getUnlimitedCheckboxValue}
                      getAllowReentryAfterInputValue={
                        this.getAllowReentryAfterInputValue
                      }
                      getTicketUsageLimitSelectValue={
                        this.getTicketUsageLimitSelectValue
                      }
                      getAllowReentryAfterCheckboxValue={
                        this.getAllowReentryAfterCheckboxValue
                      }
                    />
                  </div>
                  <div className="row">
                    <div className="col-12 text-end">
                      <Button
                        onClickHandler={this.updateShow}
                        className="reset"
                      >
                        <T _str="Save Changes" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              {!this.props.isMobileView && (
                <div className="d-flex flex-grow-1 flex-column align-items-start gap-4">
                  <div className="d-flex flex-fill w-100 flex-column gap-4 default-panel">
                    {this.state.show.imageUrl ? (
                      <img
                        src={this.state.show.imageUrl}
                        alt="Show"
                        className="img-rounded img-responsive"
                      />
                    ) : (
                      <img
                        src={ShowImage}
                        alt="Show"
                        className="img-rounded img-responsive"
                      />
                    )}
                  </div>

                  <div className="d-flex w-100 flex-column gap-4 default-panel">
                    <h6 className="m-0">Validity</h6>

                    <TinyCards
                      totalTicketsCount={this.state.totalTicketsCount}
                      ticketCountsByValidity={
                        this.state.show.ticketCountsByValidity
                      }
                    />

                    <div className="d-flex align-items-start gap-3">
                      <div className="card-cell d-flex flex-column align-items-start gap-0 p-3">
                        <label>{<T _str="Last import at" />}</label>
                        <span className="fw-bold">
                          {this.state.show.lastBarcodeImport ? (
                            moment(this.state.show.lastBarcodeImport).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )
                          ) : (
                            <T _str="N/A" />
                          )}
                        </span>
                      </div>

                      {this.state.show.lastSyncId && (
                        <div className="card-cell d-flex flex-column align-items-start gap-0 p-3">
                          <label>{<T _str="Last sync ID" />}</label>
                          <span className="fw-bold">
                            {this.state.show.lastSyncId}
                          </span>
                        </div>
                      )}
                    </div>

                    <Checkbox
                      disabled
                      name="timeslot-show"
                      placeholder="ticket-have-timeslot"
                      checked={this.state.show.useTimeSlots}
                      getValue={this.getTicketsTimeslotValue}
                      label={<T _str="Tickets for this show have timeslots" />}
                    />

                    {this.state.show.isSeeTicketsV2 && (
                      <Checkbox
                        name="box-office-mode"
                        placeholder="box-office-mode"
                        checked={this.state.show.boxOfficeMode}
                        getValue={this.getBoxOfficeModeValue}
                        label={<T _str="Box office mode" />}
                      />
                    )}
                    <div className="row">
                      {this.state.show.isSeeTicketsV2 && (
                        <div className="col-6">
                          <Button
                            size={ButtonSize.Small}
                            className="btn-secondary"
                            onClickHandler={this.importBarcode}
                          >
                            <T _str="Import Barcodes" />
                          </Button>
                        </div>
                      )}
                      <div className="col-6">
                        <DownloadButton
                          filename={scanLogsFilename}
                          onDownload={() =>
                            this.showService.getScanLogs(this.state.show.id)
                          }
                          title="Download scan logs for this show."
                        >
                          <T _str="Scan Logs" />
                        </DownloadButton>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex align-items-start gap-4">
              <Button
                className="link custom-text-error reset mx-0"
                onClickHandler={this.openConfirmationDialog}
              >
                <T _str="Delete Show" />
              </Button>
            </div>
          </div>
        )}
        {this.state.showLoadingScreen && <LoadingScreen />}

        {/* Renders delete show confirmation dialog. */}
        {this.state.showConfirmationDialog && (
          <Dialog
            title="Confirmation dialog"
            mainButton={<T _str="Delete" />}
            showModal={this.state.showConfirmationDialog}
            action={this.deleteShow}
            closeModal={this.closeConfirmationDialog}
          >
            <h4 className="text-center">
              <T
                _str="Are you sure you want to delete show {showName}?"
                showName={this.state.show.name}
              />
            </h4>
          </Dialog>
        )}

        {/* Renders error diagram if an error occur. */}
        <DialogError
          show={this.state.showErrorDialog}
          closeDialog={this.closeErrorDialog}
        >
          {this.state.errorMessages.map((message, index) => (
            <p key={index}>{message}</p>
          ))}
        </DialogError>

        {this.state.redirectToShowManagementPage && <Redirect to={"/shows"} />}
      </div>
    );
  }
}

const EditForm = ({
  show,
  formErrors,
  multiSelectData,
  unlimitedTicketUsage,
  allowReentryAfter,
  allowReentryAfterValue,
  unlimitedTicketUsageValue,
  getNameInputValue,
  isValid,
  errorService,
  getPrintTitleValue,
  getSubtitleValue,
  getBeginValue,
  getEndValue,
  getDoorsOpenTime,
  getDoorsOpenAtValue,
  handleToggleMultiSelect,
  getProviderValue,
  getExternalShowIdValue,
  getAllowReentryAfterCheckboxValue,
  getAllowReentryAfterInputValue,
  getTicketUsageLimitSelectValue,
  getUnlimitedCheckboxValue,
  getIgnoreTimeslotsValue,
  getTimeslotsGracePeriod,
  dateTimeService,
}) => {
  return (
    <div className="Edit-Form">
      <Input
        readOnly={show.providerId !== null}
        name="name"
        label={<T _str="Show name" />}
        value={show.name}
        getValue={getNameInputValue}
        onBlur={!formErrors["name"] ? isValid : null}
        onKeyUp={formErrors["name"] ? isValid : null}
      />
      {errorService.printFieldErrors(formErrors["name"])}

      <Input
        readOnly={show.providerId !== null}
        name="print-title"
        label={<T _str="Print title" />}
        value={show.printTitle}
        getValue={getPrintTitleValue}
        onBlur={!formErrors["print-title"] ? isValid : null}
        onKeyUp={formErrors["print-title"] ? isValid : null}
      />
      {errorService.printFieldErrors(formErrors["print-title"])}

      <Input
        readOnly={show.providerId !== null}
        name="subtitle"
        label={<T _str="Subtitle" />}
        value={show.subTitle}
        getValue={getSubtitleValue}
      />

      <div className="row">
        <div className="col-12 col-md-6">
          {show.providerId ? (
            <Input
              readOnly
              name="Begin"
              label={<T _str="Begin" />}
              value={moment(show.begin).format("DD-MM-yyy HH:mm")}
            />
          ) : (
            <React.Fragment>
              <label>
                <T _str="Begin" />
              </label>
              <DatePickerInput
                showTimeSelect
                initialDate={show.begin}
                callBack={(date) =>
                  getBeginValue(moment(date).format("DD-MM-YYYY HH:mm"))
                }
                className={
                  formErrors["begin"]
                    ? formErrors["begin"].length > 0
                      ? "error"
                      : ""
                    : ""
                }
              />
            </React.Fragment>
          )}
        </div>
        <div className="col-12 col-md-6">
          {show.providerId ? (
            <Input
              readOnly
              name="subtitle"
              label={<T _str="End" />}
              value={moment(show.end).format("DD-MM-yyy HH:mm")}
            />
          ) : (
            <React.Fragment>
              <label>
                <T _str="End" />
              </label>
              <DatePickerInput
                showTimeSelect
                initialDate={show.end}
                callBack={(date) =>
                  getEndValue(moment(date).format("DD-MM-YYYY HH:mm"))
                }
                className={
                  formErrors["end"]
                    ? formErrors["end"].length > 0
                      ? "error"
                      : ""
                    : ""
                }
              />
            </React.Fragment>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 doors-open">
          <Input
            readOnly={show.providerId !== null}
            placeholder="hh:mm"
            name="doors-open-at"
            value={getDoorsOpenTime()}
            label={<T _str="Doors open at" />}
            getValue={getDoorsOpenAtValue}
            onBlur={!formErrors["doors-open-at"] ? isValid : null}
            onKeyUp={formErrors["doors-open-at"] ? isValid : null}
          />
          {errorService.printFieldErrors(formErrors["doors-open-at"])}
        </div>

        <div className="multi-select col-12 col-md-6">
          {show.providerId ? (
            <Input
              readOnly
              name="Symbologies"
              label={<T _str="Symbologies" />}
              value={multiSelectData
                .filter((e) => e.selected)
                .map((i) => i.title)
                .toString()}
            />
          ) : (
            <React.Fragment>
              <span className={"barcode-label"}>
                <T _str="Symbologies" />
              </span>
              <MultiSelect
                list={multiSelectData}
                title={<T _str="Select barcode(s)" />}
                getValue={handleToggleMultiSelect}
              />
            </React.Fragment>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          <Input
            readOnly
            name="provider"
            className="read-only"
            label={<T _str="Provider" />}
            value={show.providerName}
            getValue={getProviderValue}
          />
        </div>
        <div className="col-12 col-md-6">
          <Input
            readOnly
            name="external-id"
            className="read-only"
            value={show.externalId}
            label={<T _str="External show ID" />}
            getValue={getExternalShowIdValue}
          />
        </div>
      </div>

      <div className="row reentry-and-ticket">
        <div className="col-6 col-md-12">
          <Checkbox
            name="allow-reentry-after"
            className="displayInlineBlock"
            checked={allowReentryAfter}
            label={<T _str="Allow reentry after" />}
            placeholder="allow-reentry-after"
            getValue={getAllowReentryAfterCheckboxValue}
          />
          <img
            alt="Information"
            src={IconInformation}
            className="informationIcon"
            title={
              <T _str="Select if every ticket of this show shall be allowed to reenter every day after a specified time" />
            }
          />
          <Input
            placeholder="hh:mm"
            name="allow-reenter-after"
            disabled={!allowReentryAfter}
            value={allowReentryAfterValue}
            getValue={getAllowReentryAfterInputValue}
            onBlur={!formErrors["allow-reenter-after"] ? isValid : null}
            onKeyUp={formErrors["allow-reenter-after"] ? isValid : null}
          />
          {errorService.printFieldErrors(formErrors["allow-reenter-after"])}
        </div>
        <div className="col-6 col-md-12">
          <label className="displayInlineBlock">
            <T _str="Ticket usage limit" />
          </label>
          <img
            alt="Information"
            src={IconInformation}
            className="informationIcon"
            title={
              <T _str="How often a single ticket may be checked in at the same time. Leave at 1 if you do not know better" />
            }
          />
          <Input
            maxLength={3}
            name="unlimited-ticket-usage"
            disabled={unlimitedTicketUsage}
            value={unlimitedTicketUsageValue}
            getValue={getTicketUsageLimitSelectValue}
          />
        </div>
      </div>

      <Checkbox
        name="unlimited"
        placeholder="unlimited"
        label={<T _str="Unlimited" />}
        getValue={getUnlimitedCheckboxValue}
        checked={unlimitedTicketUsage}
      />
      <Checkbox
        name="ignore-timeslots"
        placeholder="ignore-timeslots"
        label={<T _str="Ignore timeslots" />}
        getValue={getIgnoreTimeslotsValue}
        disabled={!show.useTimeSlots}
        checked={show.ignoreTimeSlots}
      />
      <Input
        maxLength={5}
        name="grace-period"
        placeholder="hh:mm"
        label={<T _str="Grace period" />}
        disabled={!show.useTimeSlots}
        getValue={getTimeslotsGracePeriod}
        onBlur={!formErrors["grace-period"] ? isValid : null}
        onKeyUp={formErrors["grace-period"] ? isValid : null}
        value={dateTimeService.getFormattedTime(show.timeSlotGracePeriod)}
      />
      {errorService.printFieldErrors(formErrors["grace-period"])}
    </div>
  );
};

const Tite = ({ name }) => {
  const t = useT();
  return (
    <SceneTitle text={t("Show Details")}>
      <p>{name}</p>
    </SceneTitle>
  );
};

export default Details;
