import React                from 'react';
import { T }                from '@transifex/react';
import UserManagerInstance  from '../../configs/login/UserManagerInstance';

import LoginIcon            from './images/ico-login.svg';
import LogoIcon             from './images/Starticket-black.svg';

import './style.css';

const Login = () =>
{
    const login = (e) =>
    {
        e.preventDefault();

        UserManagerInstance.signinRedirect();
    };

    return (
        <div className="Login">
            <div className="login-wrapper">
                <img src={LogoIcon} alt="Starticket logo"/>
                <a href="#0" onClick={login} className="btn btn-primary text-uppercase">
                    <T _str="Login with Starticket"/>
                    <img src={LoginIcon} alt="Login"/>
                </a>
            </div>
        </div>
    );
}

export default Login;