import React, { Component } from 'react';
import PropTypes            from 'prop-types';

import './style.css';

/**
 * @class themes/default/Title/components/Scene
 */
class Scene extends Component
{

    componentDidMount() {
        document.title = 'Starticket - ' + this.props.text
    }

    /**
     * @returns {XML}
     */
    render()
    {
        return (
            <div className={`Scene ${this.props.children && "has-children"}`}>
                <h1 className="title">
                    { this.props.text }
                </h1>
                {this.props.children && this.props.children }
            </div>
        );
    }
}

Scene.defaultProps = {
    text: ''
};

Scene.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default Scene;