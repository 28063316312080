import React, { Component } from "react";
import { T, useT } from "@transifex/react";
import { List } from "../../components/List";
import { SortingArrowsIcon } from "../../components/Images";
import IconLoading from "./images/ico-loading.svg";
import { VenueService } from "../../services/api/Venue";
import { LoadingScreen } from "../../components/Loading";
import { SortService } from "../../services/utils/Sort";
import { Toggle } from "../../themes/default/Toggle";
import { DialogError } from "../../themes/default/Dialog";
import { HandheldService } from "../../services/api/Handheld";
import { Pagination } from "../../components/Pagination";
import { ResponseService } from "../../services/utils/Response";
import { ListService } from "../../components/List/services";
import Dialog from "../../themes/default/Dialog/Dialog";
import { Input } from "../../themes/default/Form/components/Input";
import { Select } from "../../themes/default/Form/components/Select";
import { Switch } from "../../themes/default/Form/components/Switch";
import Search from "../../themes/default/Form/components/Search/Search";
import { CheckIcon, CloseIcon } from "../../components/Images";
import {
  ValidatorService,
  NotBlank,
  MinLength,
  MaxLength,
  Type,
} from "pretty-validator";
import {
  Button,
  ButtonAdd,
  ButtonSize,
} from "../../themes/default/Form/components/Button";

import "./style.css";

/**
 *  Stores latest timeout id.
 */
let timeoutId;

/**
 * @class scenes/VenueManagement/VenueManagement
 */
class VenueManagement extends Component {
  /**
   * @type {Object}
   */
  validatorService;

  /**
   * @type {Object}
   */
  venueService;

  /**
   * @type {Object}
   */
  sortService;

  /**
   * @type {Object}
   */
  responseService;

  state = {
    venueNameField: "",
    showAddVenueModal: false,
    newVenueName: "",
    newVenueNameErrors: [],
    searchVenueName: "",
    showArchivedVenues: false,
    showUnarchiveVenueConfirmationDialog: false,
    venues: {},
    filteredVenues: null,
    currentVenuesGroup: "All",
    venue: {
      id: "string",
      name: "string",
      useTimeframe: true,
      loadShowsInAdvance: 0,
      keepShowsAfterEnd: 0,
    },
    currentPage: 1,
    totalPageCount: 1,
    pageSize: 9999,
    totalResultsCount: null,
    showDeattachDevicesConfirmationDialog: false,
    showDeleteConfirmationDialog: false,
    venueId: null,
    showLoadingScreen: false,
    showArchiveVenueConfirmationDialog: false,
    sort: "",
    isAutoActivated: false,
    showErrorDialog: false,
    errorMessages: [],
  };

  /**
   * Constructor.
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.venueService = new VenueService();
    this.sortService = new SortService();
    this.handheldService = new HandheldService();
    this.validatorService = new ValidatorService();
    this.responseService = new ResponseService();
  }

  /**
   * @override
   */
  componentDidMount() {
    this.handleVenueList(
      this.state.currentPage,
      this.state.pageSize,
      this.state.sort
    );
  }

  /**
   * Retrieves list of venues and sets state accordingly.
   *
   * @param {Number} page
   * @param {Number} pageSize
   * @param {String} sort
   */
  handleVenueList = (page, pageSize, sort) => {
    let filter = "";
    filter += !this.state.showArchivedVenues
      ? "archived==" + this.state.showArchivedVenues
      : "";
    if (this.state.searchVenueName !== "") {
      if (filter !== "") {
        filter += ",";
      }
      filter += "name@=*" + this.state.searchVenueName;
    } else {
      filter += "";
    }

    let parameterObject = {
      ...(sort !== "" ? { Sort: sort } : {}),
      Filter: filter,
      Page: page,
      PageSize: pageSize,
    };
    this.getVenueList(parameterObject);
  };

  getVenueList = (parameterObject) => {
    const { currentVenuesGroup, venues, showArchivedVenues } = this.state;

    this.setState({
      showLoadingScreen: true,
    });
    this.venueService.getAll(parameterObject).then((response) => {
      if (response.status === 200) {
        if (JSON.stringify(response.data) !== JSON.stringify(venues)) {
          this.setState({
            venues: response.data,
            currentPage: response.data.currentPage,
            totalPageCount: response.data.totalPageCount,
            totalResultsCount: !showArchivedVenues
              ? response.data.totalResultCount
              : null,
          });
          this.getGroupFilterValue(currentVenuesGroup, response.data);
        }
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
        });
      }
      this.setState({
        showLoadingScreen: false,
      });
    });
  };

  /**
   * Handles state when opening New Venue modal.
   */
  handleAddVenueButtonClick = () => this.setState({ showAddVenueModal: true });

  /**
   * Checks if venue name is valid and if not adds error to input field.
   *
   * @returns {Boolean}
   */
  isValidVenueName = (name = this.state.newVenueName) => {
    let errors = this.validatorService.validate(name, [
      new NotBlank(),
      new MinLength(3),
      new MaxLength(50),
      new Type("string"),
    ]);

    let isValid = errors.length === 0;

    if (!isValid) {
      this.setState({
        newVenueNameErrors: errors,
      });
    }

    return isValid;
  };

  /**
   * Sends request for creating new venue.
   */
  createNewVenue = () => {
    if (this.isValidVenueName()) {
      this.setState({
        showLoadingScreen: true,
      });
      this.venueService
        .createOne({
          name: this.state.newVenueName,
          autoActivateShows: this.state.isAutoActivated,
        })
        .then((response) => {
          if (response.status === 201) {
            this.setState({
              showLoadingScreen: false,
            });

            this.props.history.push(`/venues/shows/${response.data.id}`);
          } else {
            this.setState({
              showErrorDialog: true,
              showLoadingScreen: false,
              errorMessages: this.responseService.getErrorMessages(
                response.data
              ),
            });
          }
        });

      this.setState({
        newVenueNameErrors: "",
        showAddVenueModal: false,
      });
    }
  };

  /**
   * Updates state value responsible for showing create new venue modal.
   */
  closeAddVenueModal = () => {
    this.setState({
      newVenueNameErrors: "",
      newVenueName: "",
      showAddVenueModal: false,
    });
  };

  /**
   * Updates the state of component based on switch position.
   */
  handleActivateShowsClick = () =>
    this.setState((prevState) => ({
      isAutoActivated: !prevState.isAutoActivated,
    }));

  /**
   * Sets state when adding new value.
   *
   * @param value
   */
  receiveValue = (value) => {
    let nameFieldErrors = this.state.newVenueNameErrors;

    this.setState({
      newVenueName: value,
      newVenueNameErrors: this.isValidVenueName(value) ? [] : nameFieldErrors,
    });
  };

  /**
   * Retrieves list of venues that match search value.
   *
   * @param searchValue
   */
  receiveSearchValue = (searchValue) => {
    let value = encodeURIComponent(searchValue);
    let Filter = "name@=*" + value;

    if (this.state.showArchivedVenues === false) {
      Filter += ",archived==false";
    }

    let parameterObject = {
      Filter: Filter,
      PageSize: this.state.pageSize,
      Page: 1,
    };

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      if (value.length > 2 || value.length === 0) {
        this.setState({
          showLoadingScreen: true,
          searchVenueName: value,
        });
        this.getVenueList(parameterObject);
      }
    }, 300);
  };

  /**
   * Calculates parameter object based on isChecked property.
   *
   * @param {Boolean} isChecked
   *
   * @returns {Object}
   */
  calculateParameterObject = (isChecked) => {
    let queryParameter = "";
    if (isChecked === false) {
      queryParameter = "archived==" + isChecked;
    }

    if (this.state.searchVenueName !== "") {
      if (queryParameter !== "") {
        queryParameter += ",";
      }
      queryParameter += "name@=*" + this.state.searchVenueName;
    }

    let page = isChecked
      ? this.state.currentPage
      : this.state.currentPage >
        Math.ceil(this.state.totalResultsCount / this.state.pageSize)
      ? Math.ceil(this.state.totalResultsCount / this.state.pageSize)
      : this.state.currentPage;
    return {
      Filter: queryParameter ? queryParameter : null,
      Page: page,
      PageSize: this.state.pageSize,
    };
  };

  /**
   * Returns if show archived values checkbox is checked and updates state.
   *
   * @param {Boolean} isChecked
   */
  getShowArchivedVenuesValue = (isChecked) => {
    let parameterObject = this.calculateParameterObject(isChecked);

    this.setState({
      showLoadingScreen: true,
      showArchivedVenues: isChecked,
    });

    this.getVenueList(parameterObject);
  };

  /**
   * Updates venue's state value according to selected page.
   *
   * @param {Number} page
   */
  changePageHandler = (page) => {
    if (this.state.currentPage !== page) {
      this.handleVenueList(page, this.state.pageSize, this.state.sort);
    }
  };

  /**
   * Deattach all devices from particular venue.
   *
   * @param {String} venueId
   */
  deattachDevices = (venueId) => {
    let parameterObject = {
      PageSize: 9999,
    };
    this.setState({
      showLoadingScreen: true,
    });
    this.venueService
      .getAllHandhelds(venueId, parameterObject)
      .then((response) => {
        if (response.status === 200) {
          let handhelds = response.data.results;
          handhelds.map((handheld) => {
            this.handheldService
              .assignOrUnassignFromVenue(handheld.id, { venue: null })
              .then((response) => {
                if (response.status !== 204) {
                  this.setState({
                    showErrorDialog: true,
                    errorMessages: this.responseService.getErrorMessages(
                      response.data
                    ),
                    showLoadingScreen: false,
                  });
                }
              });

            return true;
          });

          this.handleVenueList(
            this.state.currentPage,
            this.state.pageSize,
            this.state.sort
          );
          this.setState({
            showDeattachDevicesConfirmationDialog: false,
            venueId: null,
            showLoadingScreen: false,
          });
        } else {
          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(response.data),
            showLoadingScreen: false,
          });
        }
      });
  };

  /**
   * Updates state and opens deattach devices confirmation dialog.
   */
  openDeattachDevicesConfirmationDialog = (venueId) =>
    this.setState({
      showDeattachDevicesConfirmationDialog: true,
      venueId: venueId,
    });

  /**
   * Updates state and opens confirmation dialog.
   *
   * @param {String} venueId
   */
  openDeleteConfirmationDialog = (venueId) =>
    this.setState({
      showDeleteConfirmationDialog: true,
      venueId: venueId,
    });

  /**
   * Updates state and closes deattach devices confirmation dialog.
   */
  closeDeattachDevicesConfirmationDialog = () =>
    this.setState({
      showDeattachDevicesConfirmationDialog: false,
      venueId: null,
    });

  /**
   * Updates state and closes delete confirmation dialog.
   */
  closeDeleteConfirmationDialog = () =>
    this.setState({ showDeleteConfirmationDialog: false });

  /**
   * Sets a particular venue to be not archived.
   *
   * @param {String} venueId
   */
  unarchiveVenue = (venueId) => {
    this.setState({
      showLoadingScreen: true,
    });
    this.venueService.getOne(venueId).then((response) => {
      if (response.status === 200) {
        let venueName = response.data.name;

        this.venueService
          .updateOne(venueId, { name: venueName, archived: false })
          .then((response) => {
            if (response.status === 204) {
              this.handleVenueList(
                this.state.currentPage,
                this.state.pageSize,
                this.state.sort
              );
              this.setState({
                showUnarchiveVenueConfirmationDialog: false,
                showLoadingScreen: false,
                venueId: null,
              });
            } else {
              this.setState({
                showErrorDialog: true,
                errorMessages: this.responseService.getErrorMessages(
                  response.data
                ),
                showLoadingScreen: false,
              });
            }
          });
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
          showLoadingScreen: false,
        });
      }
    });
  };

  /**
   * Updates state and opens unarchive venue confirmation dialog.
   *
   * @param {String} venueId
   */
  openUnarchiveVenueConfirmationDialog = (venueId) =>
    this.setState({
      showUnarchiveVenueConfirmationDialog: true,
      venueId: venueId,
    });

  /**
   * Updates state and closes unarchive venue confirmation dialog.
   */
  closeUnarchiveVenueConfirmationDialog = () =>
    this.setState({
      showUnarchiveVenueConfirmationDialog: false,
      venueId: null,
    });

  /**
   * Sets a particular venue to be archived.
   *
   * @param {String} venueId
   */
  archiveVenue = (venueId) => {
    this.setState({
      showLoadingScreen: true,
    });
    this.venueService.getOne(venueId).then((response) => {
      if (response.status === 200) {
        let venueName = response.data.name;

        this.venueService
          .updateOne(venueId, { name: venueName, archived: true })
          .then((response) => {
            if (response.status === 204) {
              let page =
                this.state.totalResultsCount % this.state.pageSize === 1
                  ? this.state.currentPage - 1
                  : this.state.currentPage;
              this.handleVenueList(page, this.state.pageSize, this.state.sort);
              this.setState({
                showArchiveVenueConfirmationDialog: false,
                showLoadingScreen: false,
                venueId: null,
              });
            } else {
              this.setState({
                showErrorDialog: true,
                errorMessages: this.responseService.getErrorMessages(
                  response.data
                ),
                showLoadingScreen: false,
              });
            }
          });
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
          showLoadingScreen: false,
        });
      }
    });
  };

  /**
   * Updates state and opens archive venue confirmation dialog.
   *
   * @param {String} venueId
   */
  openArchiveVenueConfirmationDialog = (venueId) => {
    this.setState({
      showArchiveVenueConfirmationDialog: true,
      venueId: venueId,
    });
  };

  /**
   * Updates state and closes archive venue confirmation dialog.
   */
  closeArchiveVenueConfirmationDialog = () => {
    this.setState({
      showArchiveVenueConfirmationDialog: false,
      venueId: null,
    });
  };

  /**
   * Removes Venue.
   *
   * @param {String} venueId
   */
  deleteVenue = (venueId) => {
    this.setState({
      showLoadingScreen: true,
    });
    this.venueService.deleteOne(venueId).then((response) => {
      if (response.status === 204) {
        let page =
          this.state.totalResultsCount % this.state.pageSize === 1
            ? this.state.currentPage - 1
            : this.state.currentPage;
        this.handleVenueList(page, this.state.pageSize, this.state.sort);
        this.closeDeleteConfirmationDialog();
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
        });
      }
      this.setState({
        showLoadingScreen: false,
      });
    });
  };

  /**
   * Sorts Venue name column ascending or descending and sets state accordingly.
   *
   * @param {String} column
   */
  sortColumn = (column) => {
    let sort = this.sortService.getSortValue(this.state.sort, column);

    this.setState({
      sort: sort,
    });

    this.handleVenueList(this.state.currentPage, this.state.pageSize, sort);
  };

  /**
   * Updates state and closes error dialog.
   */
  closeErrorDialog = () => this.setState({ showErrorDialog: false });

  daysUntilNextShowFormatter = (value) => {
    let daysUntilNextShow = value;
    let className = "days-cell";

    if (value < 0) daysUntilNextShow = "-";
    else if (value === 0) {
      daysUntilNextShow = "TODAY";
      className += " green-bold-text";
    } else if (0 < value && value <= 7) className += " yellow-text";

    return {
      cellData: daysUntilNextShow,
      className: className,
    };
  };

  /**
   * Returns adapted data for venue dashboard list.
   *
   * @param {Object} responseData
   *
   * @returns {Object}
   */
  getVenueData = (responseData, isMobileView) => {
    let tableData = {};

    responseData["results"] &&
      responseData["results"].map((venue) => {
        let venueData = [];
        let venuesColumn = {
          cellData: venue.name,
          href: "/venues/dashboard/",
        };
        venueData.push(venuesColumn);

        if (!isMobileView) {
          let showsColumn = {
            cellData: venue.numShows,
            className: "shows-cell",
          };
          venueData.push(showsColumn);

          let daysColumn = this.daysUntilNextShowFormatter(
            venue.daysUntilNextShow
          );
          venueData.push(daysColumn);

          let devicesColumn = {
            cellData: venue.numHandhelds,
            className: "devices-cell",
          };
          venueData.push(devicesColumn);

          let IsolationColum = {
            cellData: venue.isolated ? CheckIcon() : CloseIcon(),
            className: venue.isolated ? "isolated" : "not isolated",
            isImage: true,
          };
          venueData.push(IsolationColum);

          let IsBYOD = {
            cellData: venue.promoterId ? CheckIcon() : CloseIcon(),
            className: "byod",
            isImage: true,
          };
          venueData.push(IsBYOD);

          let optionColum = {
            options: {
              archived: venue.archived,
              deattached: !venue.numHandhelds,
            },
          };
          venueData.push(optionColum);
        }

        tableData[venue.id] = venueData;

        return true;
      });

    return tableData;
  };

  getGroupFilterValue = (value, response) => {
    const data = response
      ? [...response.results]
      : [...this.state.venues.results];
    let results;

    value === "Admin"
      ? (results = data.filter((i) => i.promoterId === null))
      : value === "Promoter (BYOD)"
      ? (results = data.filter((i) => i.promoterId !== null))
      : (results = null);

    const filteredVenues = results
      ? {
          ...this.state.venues,
          results,
        }
      : null;

    this.setState({ filteredVenues, currentVenuesGroup: value });
  };

  /**
   * @returns {XML}
   */
  render() {
    const {
      venues,
      currentPage,
      totalPageCount,
      showAddVenueModal,
      newVenueNameErrors,
      isAutoActivated,
      showDeattachDevicesConfirmationDialog,
      venueId,
      showLoadingScreen,
      showDeleteConfirmationDialog,
      showArchiveVenueConfirmationDialog,
      showUnarchiveVenueConfirmationDialog,
      showErrorDialog,
      errorMessages,
      filteredVenues,
    } = this.state;

    const { isMobileView } = this.props;
    return (
      <div className="col-12 p-0 VenueManagement">
        <TableOptions
          {...this.state}
          handleVenueList={this.handleVenueList}
          receiveSearchValue={this.receiveSearchValue}
          handleAddVenueButtonClick={this.handleAddVenueButtonClick}
          getShowArchivedVenuesValue={this.getShowArchivedVenuesValue}
          getGroupFilterValue={(e) => this.getGroupFilterValue(e.value)}
        />

        <List
          sort={this.sortColumn}
          deleteHandler={this.openDeleteConfirmationDialog}
          columns="VenuesHeadRow"
          currentSorting={this.state.sort}
          data={this.getVenueData(
            filteredVenues ? filteredVenues : venues,
            isMobileView
          )}
          archiveVenue={this.openArchiveVenueConfirmationDialog}
          unarchiveVenue={this.openUnarchiveVenueConfirmationDialog}
          deattachDevices={this.openDeattachDevicesConfirmationDialog}
          showLoadingScreen={this.state.showLoadingScreen}
        />

        <Pagination
          changePageHandler={this.changePageHandler}
          currentPage={currentPage}
          totalPageCount={totalPageCount}
        />

        {
          //Shows New Venue dialog.
          showAddVenueModal && (
            <Dialog
              title={<T _str="Create new venue" />}
              showModal={showAddVenueModal}
              action={() => this.createNewVenue()}
              closeModal={this.closeAddVenueModal}
            >
              <Input
                label={<T _str="Name of venue" />}
                name={"Venue name"}
                getValue={this.receiveValue}
                className={newVenueNameErrors.length > 0 ? "error" : ""}
                focus
              />
              <div className="row switch-padding-top">
                <div className="col-6">
                  <T _str="Auto Activate Shows" />
                </div>
                <div className="col-6 right-position">
                  <Switch
                    name="isAutoActivated"
                    label=""
                    checked={isAutoActivated}
                    getValue={this.handleActivateShowsClick}
                  />
                </div>
              </div>
              {newVenueNameErrors.length > 0 &&
                newVenueNameErrors.map((error, key) => (
                  <div key={key} className="error">
                    - {error}
                  </div>
                ))}
            </Dialog>
          )
        }

        {
          //Renders deattach devices confirmation dialog.
          showDeattachDevicesConfirmationDialog && (
            <Dialog
              mainButton={<T _str="confirm" />}
              title={<T _str="Confirmation dialog" />}
              action={() => this.deattachDevices(venueId)}
              showModal={showDeattachDevicesConfirmationDialog}
              closeModal={this.closeDeattachDevicesConfirmationDialog}
            >
              <h4 className="text-center">
                <T _str="Are you sure you want to deattach all devices from this venue?" />
              </h4>
            </Dialog>
          )
        }

        {
          // Renders confirmation dialog.
          showDeleteConfirmationDialog && (
            <Dialog
              mainButton={<T _str="confirm" />}
              title={<T _str="Confirmation dialog" />}
              showModal={showDeleteConfirmationDialog}
              action={() => this.deleteVenue(venueId)}
              closeModal={this.closeDeleteConfirmationDialog}
            >
              <h4 className="text-center">
                <T _str="Are you sure you want to delete this venue?" />
              </h4>
            </Dialog>
          )
        }

        {
          // Renders archive venue confirmation dialog.
          showArchiveVenueConfirmationDialog && (
            <Dialog
              mainButton={<T _str="confirm" />}
              title={<T _str="Confirmation dialog" />}
              action={() => this.archiveVenue(venueId)}
              showModal={showArchiveVenueConfirmationDialog}
              closeModal={this.closeArchiveVenueConfirmationDialog}
            >
              <h4 className="text-center">
                <T _str="Are you sure you want to archive this venue?" />
              </h4>
            </Dialog>
          )
        }

        {
          // Renders unarchive venue confirmation dialog.
          showUnarchiveVenueConfirmationDialog && (
            <Dialog
              mainButton={<T _str="confirm" />}
              title={<T _str="Confirmation dialog" />}
              action={() => this.unarchiveVenue(venueId)}
              showModal={showUnarchiveVenueConfirmationDialog}
              closeModal={this.closeUnarchiveVenueConfirmationDialog}
            >
              <h4 className="text-center">
                <T _str="Are you sure you want to return this venue from archive?" />
              </h4>
            </Dialog>
          )
        }

        {showLoadingScreen && <LoadingScreen />}

        {/* Renders error diagram if an error occur. */}
        <DialogError show={showErrorDialog} closeDialog={this.closeErrorDialog}>
          {errorMessages.map((message, index) => (
            <p key={index}>{message}</p>
          ))}
        </DialogError>
      </div>
    );
  }
}

/**
 * Renders table options area (search venues, show archived venues, create new venue and refresh venues).
 *
 * @returns {XML}
 */
const TableOptions = ({
  currentVenuesGroup,
  receiveSearchValue,
  showArchivedVenues,
  handleAddVenueButtonClick,
  getShowArchivedVenuesValue,
  handleVenueList,
  currentPage,
  pageSize,
  sort,
  getGroupFilterValue,
}) => {
  const t = useT();
  document.title = "Starticket - " + t("Venues");

  return (
    <div className="row table-options">
      <div className="col-8">
        <div className="d-flex gap-3">
          <Search
            name="search"
            placeholder={<T _str="Search" />}
            getValue={receiveSearchValue}
            className={` m-0`}
          />
          <div className="venues-filter-menu">
            <Select
              data={{
                All: "All",
                Admin: "Admin",
                "Promoter (BYOD)": "Promoter (BYOD)",
              }}
              getValue={getGroupFilterValue}
              initialValue={currentVenuesGroup}
              className={`m-0`}
            />
          </div>
          <div className="archived-venues">
            <Toggle
              label={<T _str="Show archived venues" />}
              isChecked={showArchivedVenues}
              callBack={getShowArchivedVenuesValue}
            />
          </div>
        </div>
      </div>
      <div className="col-4 d-flex justify-content-end">
        <Button
          className={ButtonAdd}
          onClickHandler={() => handleAddVenueButtonClick()}
        >
          <T _str="Create new" />
        </Button>
        <Button
          className={ButtonSize.Tiny}
          onClickHandler={() => handleVenueList(currentPage, pageSize, sort)}
        >
          <img
            src={IconLoading}
            alt={t("Loading")}
            title={t("Refresh venues")}
          />
        </Button>
      </div>
    </div>
  );
};

/**
 * Renders list head row.
 *
 * @param {Object} columns
 *
 * @returns {any[]}
 */
export const VenuesHeadRow = ({ sort, currentSorting }) => {
  const listServices = new ListService();

  const t = useT();

  const venue = t("Venue");
  const shows = t("Shows");
  const days = t("Days until next show");
  const devices = t("Devices");
  const isolation = t("Isolation");

  const venueColumns = {
    [venue]: {
      subtitle: null,
      sortable: true,
      fieldValue: "name",
      className: "venue-col",
    },
    [shows]: {
      subtitle: null,
      sortable: true,
      fieldValue: "numShows",
      className: "shows-col",
    },
    [days]: {
      subtitle: null,
      sortable: true,
      fieldValue: "daysUntilNextShow",
      className: "days-col",
    },
    [devices]: {
      subtitle: null,
      sortable: true,
      fieldValue: "numHandhelds",
      className: "devices-col",
    },
    [isolation]: {
      subtitle: null,
      sortable: false,
      className: "isolation-col",
    },
    BYOD: { subtitle: null, sortable: false, className: "isBYOD-col" },
    " ": { subtitle: null, sortable: false, className: "options-col" },
  };

  return (
    <tr>
      {Object.entries(venueColumns).map((column, index) => (
        <th
          className={column[1].className ? column[1].className : ""}
          key={index}
        >
          {column[0]}
          {column[1].subtitle !== null && (
            <span className="grayed">{column[1].subtitle}</span>
          )}
          {column[1].sortable && (
            <SortingArrowsIcon
              className={`pull-right sorting-arrow ${listServices.sortingArrowClassController(
                column,
                currentSorting
              )}`}
              onClick={() => sort(column[1].fieldValue)}
            />
          )}
        </th>
      ))}
    </tr>
  );
};

export default VenueManagement;
