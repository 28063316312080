import React, { useState }   from 'react';
import PropTypes             from 'prop-types';
import { NavLink }           from 'react-router-dom';
import { UserMenu }          from './components/UserMenu';
import { TopNavigationMenu } from './components/TopNavigationMenu';
import UserManagerInstance   from '../../../../../configs/login/UserManagerInstance';

import IconMore              from './images/ico-more.svg';
import IconLogo              from './images/Starticket-logo-white.svg';

import './style.css';

const Header = ({isHomeButtonClicked, isMobileView, showSidebar, toggleSidebar, topNavigationMenuItems, loggedUser }) =>
{
    const [showUserMenu , setShowUserMenu] = useState(false);

    const showUserMenuButton = 
        isMobileView
        ?   !showSidebar
        :   true

    return (
        <div className="Header m-0 p-0 row" >
            <div className="col-4 text-center nav-left p-0">
                {isMobileView
                    ?   <div className={`col-2 hamburger-menu ${!showSidebar ? "black" : "colored"} `} onClick={toggleSidebar}>
                            <SidebarIcon />
                        </div>
                    :   <TopNavigationMenu
                            showSidebar={showSidebar}
                            topNavigationMenuItems={topNavigationMenuItems}
                            isHouseButtonClicked={() => isHomeButtonClicked && isHomeButtonClicked()}
                        />
                }
            </div>
            <div className="col-4 text-center p-0 logo">
                <NavLink exact to="/">
                    <img src={IconLogo} alt="Starticket logo" title="Starticket" onClick={() => isHomeButtonClicked && isHomeButtonClicked() }/>
                </NavLink>
            </div>
            <div className="col-4 text-end nav-right p-0">
                <span className="text-uppercase user-details">
                    {loggedUser?.firstName}&nbsp;{loggedUser?.lastName}
                </span>
            
                {showUserMenuButton &&
                    <span onClick={() => setShowUserMenu(!showUserMenu)}>
                        <img src={IconMore} alt="Icon more" className="icon-more" title="More options" />
                    </span>
                }
            </div>

            {showUserMenu && showUserMenuButton &&
                <UserMenu
                    show={showUserMenu}
                    loggedUser={loggedUser}
                    closeMenu={() => setShowUserMenu(false )}
                    logout={() => UserManagerInstance.signoutRedirect() }
                />
            }
        </div>
    );
};

const SidebarIcon = () => (
    <div className="icon-doubleLineHamburger">
        <div className="hamburger-icon-top"></div>
        <div className="hamburger-icon-patty"></div>
        <div className="hamburger-icon-bottom"></div>
    </div>
)

Header.propTypes = {
    showSidebar : PropTypes.bool,
    toggleSidebar : PropTypes.func,
    topNavigationMenuItems : PropTypes.object.isRequired,
    isHomeButtonClicked : PropTypes.func
};

export default Header;