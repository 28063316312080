import React, { useState }      from 'react';
import PropTypes                from 'prop-types';
import { T, useT }              from '@transifex/react';
import IconDelete               from './images/ico-delete.svg';
import IconReset                from './images/ico-loading.svg';
import { BatteryIcon }          from '../../../../components/Images';
import { CheckIcon, CloseIcon } from '../../../../components/Images';
import { SortingArrowsIcon }    from '../../../../components/Images';
import {LoadingForTables}       from '../../../../components/Loading';
import { DialogError }          from '../../../../themes/default/Dialog';

import './style.css';

const List = ({ showLoadingScreen, sort, data, editDevice, resetDevice, removeDevice, errorMessages, isMobileView, currentSorting }) =>
{
    const [ showErrorDialog, setShowErrorDialog] = useState(false);

    return (
        <React.Fragment>
            <table className="List table-hover table table-striped">
                <thead>
                    <tr>
                        <RenderHeadRow sort={sort} currentSorting={currentSorting} isMobileView={isMobileView} />
                    </tr>
                </thead>
                
                <tbody>
                    { Object.entries(data).length > 0 && !showLoadingScreen &&
                        <RenderBody
                            data={data}
                            editDevice={editDevice}
                            resetDevice={resetDevice}
                            isMobileView={isMobileView}
                            removeDevice={removeDevice}
                            showLoadingScreen={showLoadingScreen}
                        />
                    }
                </tbody>
                
                <RenderErrorDialog
                    errorMessages={errorMessages}
                    showErrorDialog={showErrorDialog}
                    closeErrorDialog={() => setShowErrorDialog(false)}
                />

            </table>

            <IsLoadingOrNoResults 
                data={data}
                showLoadingScreen={showLoadingScreen}
            />

        </React.Fragment>
    )
}

const RenderHeadRow = ({ sort, currentSorting, isMobileView }) => {
    const t = useT();

    const extraColumns = {
        [t('Model')]      : { 'subtitle' : null, 'sortable' : true, 'fieldValue': 'deviceModel' },
        [t('Battery')]    : { 'subtitle' : null, 'sortable' : true, 'fieldValue':'batteryLevel', 'className': 'devices-battery-column' },
        [t('AppVersion')] : { 'subtitle' : null, 'sortable' : true, 'fieldValue': 'appVersion', 'className': 'devices-appVersion-column' },
        [t('Connection  (last seen)')] : { 'subtitle' : null, 'sortable' : true , 'fieldValue': 'lastStatusReport' },
        [t('Staged to venue')]     : { 'subtitle' : null, 'sortable' : false }       
    }

    const deviceListColumnsHeader = {
        [t('Device')]     : { 'subtitle' : null, 'sortable' : true, 'fieldValue': 'name' },
        ...(!isMobileView && extraColumns),
        "BYOD"     : { 'subtitle' : null, 'sortable' : false, 'className': 'byod-column' },
        ''           : { 'subtitle' : null, 'sortable' : false, 'className': 'devices-buttons-column' }
    };

    const sortingArrowClassController = (column) => {

        let sortDirection = 'no-sort';
        let sorting = currentSorting;
        let isNegative = false;
        
        if (currentSorting[0] === '-') {
            sorting = currentSorting.substring(1);
            isNegative = true;
        }

        if (sorting.toLowerCase() === column[1].fieldValue.toLowerCase()) {
            if (sorting !== '') {
                isNegative
                    ? sortDirection = 'ascending-sort'
                    : sortDirection = 'descending-sort';
            };
        }

        return sortDirection;
    }

    return Object.entries(deviceListColumnsHeader).map((column,index) => (
        <th key={index} className={column[1].className ? column[1].className : ""}>
            { column[0] }
            {
                column[1].subtitle !== null && <span className="grayed">{column[1].subtitle}</span>
            }
            {
                column[1].sortable &&
                    <SortingArrowsIcon className={`pull-right sorting-arrow ${ sortingArrowClassController(column) }`} onClick={() => sort(column[1].fieldValue)} />
            }
        </th>
    ))
};

const RenderBody = ({data, showLoadingScreen, editDevice, resetDevice, removeDevice, isMobileView }) =>
{
    const t = useT();
    /* 
    * Returns appropriate venue data according to staging status of handheld.
    */
    const renderVenueData = (rowData) =>
     {
        let venueName = '';

        if (rowData.venueId && rowData.venueId !== "") {
            if (rowData.stagingStatus !== 'destaged')
                venueName = rowData.venueName;
            else venueName = t("destaged");
        }
        else venueName = t("destaged");
 
        return venueName;
     };

    if (Object.entries(data).length > 0 && !showLoadingScreen) {
        return data.map((rowData,index) => {
            let stagingStatus = rowData.stagingStatus === 'destaged' ? t('destaged') : rowData.connection;

            const slugifyContent = rowData.name.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');

            const deleteImageTitle = t("Delete device") + " " + rowData.name;
            const deleteImageClass = "delete-device-" + slugifyContent;
            const resetImageTitle = t("Reset device") + " " + rowData.name;

            return (
                <tr key={index}>
                    <td className='devices-id-cell' title={rowData.name} onClick={ () => editDevice(rowData) }>
                        {rowData.name}
                    </td>

                    {!isMobileView &&
                        <React.Fragment>
                            <td title={rowData.model} className="color-black">
                                {rowData.model}
                            </td>
                            <td title={`${rowData.battery.data}%`} className={rowData.battery.className}>
                                <BatteryIcon batteryPercentage={rowData.battery.data} />
                            </td>
                            <td title={rowData.appVersion} className="appVersion-cell">
                                {rowData.appVersion}
                            </td>
                            <td
                                title={stagingStatus === 'destaged' ? t("destaged") : stagingStatus }
                                className={`color-black ${rowData.stagingStatus === 'destaged' ? 'destaged': ''}`}
                            >
                                {stagingStatus === 'destaged' ? <T _str="destaged"/> : stagingStatus }
                            </td>
                            <td title={renderVenueData(rowData)} className={ rowData.stagingStatus === 'destaged' ? 'destaged' : ''}>
                                { renderVenueData(rowData) }
                            </td>
                        </React.Fragment>
                    }

                    <td className='byod-cell'>
                        {rowData.isBYOD ? <CheckIcon /> : <CloseIcon/>}
                    </td>
                    <td>
                        <span className="pull-right options">
                            <img src={ IconReset }
                                alt={resetImageTitle}
                                title={resetImageTitle}
                                onClick={() => resetDevice(rowData) }
                            />
                            <img src={ IconDelete }
                                alt={deleteImageTitle}
                                title={deleteImageTitle}
                                className={deleteImageClass}
                                onClick={() => removeDevice(rowData) }
                            />

                        </span>
                    </td>
                </tr>
            );
        });
    }
};

const RenderErrorDialog = ({ showErrorDialog, closeErrorDialog, errorMessages }) =>
(
    <DialogError show={showErrorDialog} closeDialog={closeErrorDialog}>
        {
            errorMessages.map((message,index) => <p key={index}>{message}</p>)
        }
    </DialogError>
);

const IsLoadingOrNoResults = ({ showLoadingScreen, data }) => {
    if (showLoadingScreen) {
        return <LoadingForTables />
    }
    else if (!showLoadingScreen && Object.entries(data).length === 0) {
        return <div className='no-results'><T _str="NO RESULTS FOUND"/></div>
    }
    return null
};

List.propTypes = {
    sort : PropTypes.func,
    data : PropTypes.array,
    isLoaded : PropTypes.func,
    editDevice : PropTypes.func,
    resetDevice : PropTypes.func,
    removeDevice : PropTypes.func,
    errorMessages : PropTypes.array,
    showLoadingScreen : PropTypes.bool
};

export default List;