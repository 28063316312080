import React, { Component } from "react";
import { T, useT } from "@transifex/react";
import {
  ValidatorService,
  NotBlank,
  MinLength,
  MaxLength,
  Type,
} from "pretty-validator";
import { List } from "../../components/List";
import {
  CheckIcon,
  CloseIcon,
  SortingArrowsIcon,
} from "../../components/Images";
import { EditDialog } from "./components/EditDialog";
import { LoadingScreen } from "../../components/Loading";
import { SortService } from "../../services/utils/Sort";
import { ProviderService } from "../../services/api/Provider";
import { Pagination } from "../../components/Pagination";
import { DialogError } from "../../themes/default/Dialog";
import { Toggle } from "../../themes/default/Toggle";
import { ResponseService } from "../../services/utils/Response";
import { RefreshButton } from "../../components/RefreshButton";
import { ListService } from "../../components/List/services";
import { ConfirmationDialog } from "./components/ConfirmationDialog";
import Dialog from "../../themes/default/Dialog/Dialog";
import { Input } from "../../themes/default/Form/components/Input";
import {
  Button,
  ButtonAdd,
  ButtonSize,
} from "../../themes/default/Form/components/Button";
import Search from "../../themes/default/Form/components/Search/Search";

import "./style.css";

/**
 *  Stores latest timeout id.
 */
let timeoutId;

class ProviderManagement extends Component {
  /**
   * @type {Object}
   */
  validatorService;

  /**
   * @type {Object}
   */
  providerService;

  /**
   * @type {Object}
   */
  sortService;

  /**
   * @type {Object}
   */
  responseService;

  state = {
    newProviderName: "",
    showAddProviderModal: false,
    showLoadingScreen: false,
    newProviderNameErrors: [],
    searchProviderName: "",
    showActiveProviders: false,
    currentPage: 1,
    totalPageCount: 1,
    pageSize: 9999,
    totalResultsCount: null,
    sort: "",
    newProviderClientIdValue: "",
    newProviderNameValue: "",
    providers: {},
    copied: false,
    providerData: {},
    showEditProviderDialog: false,
    showDeleteConfirmationDialog: false,
    showClientSecretNotCopiedDialog: false,
    showErrorDialog: false,
    clientCredential: "",
    changeClientSecretConfirmationDialog: false,
    errorMessages: [],
    selectedProvider: {},
    newProviderisV2SeeTicketsValue: null,
    V2Configuration: {}, // null for none V2 Providers
    isPreDefineProvider: false,
  };

  /**
   * Constructor.
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.providerService = new ProviderService();
    this.sortService = new SortService();
    this.validatorService = new ValidatorService();
    this.responseService = new ResponseService();
  }

  /**
   * @override
   */
  componentDidMount() {
    this.handleProviderList(
      this.state.currentPage,
      this.state.pageSize,
      this.state.sort
    );
  }

  /**
   * Retrieves list of providers and sets state accordingly.
   *
   * @param {Number} page
   * @param {Number} pageSize
   * @param {String} sort
   */
  handleProviderList = (page, pageSize, sort) => {
    let parameterObject = {
      ...(sort !== "" ? { Sort: sort } : {}),
      ...(this.state.searchProviderName !== ""
        ? { Filter: "name@=*" + this.state.searchProviderName }
        : {}),
      PageSize: pageSize,
      Page: page,
    };
    this.setLoadingScreen(true);
    this.fetchProviders(parameterObject);
  };

  /**
   * Updates providers's state value according to selected page.
   *
   * @param {Number} page
   */
  changePageHandler = (page) => {
    if (this.state.currentPage !== page) {
      this.handleProviderList(page, this.state.pageSize, this.state.sort);
    }
  };

  /**
   * Sorts Provider name column ascending or descending and sets state accordingly.
   *
   * @param {String} column
   */
  sortColumn = (column) => {
    let sort = this.sortService.getSortValue(this.state.sort, column);

    this.setState({
      sort: sort,
    });

    this.handleProviderList(this.state.currentPage, this.state.pageSize, sort);
  };

  /**
   * Sends request for creating new provider. then refresh the provider list and open the edit provider dialog
   */
  createNewProvider = () => {
    if (this.isValidProviderName()) {
      this.setLoadingScreen(true);
      this.providerService
        .createOne({
          name: this.state.newProviderName,
        })
        .then((response) => {
          if (response.status === 200) {
            const provider = {
              name: {
                cellData: response.data.name,
              },
              shows: 0,
              clientId: response.data.clientId,
              active: true,
              clientSecret: response.data.clientSecret,
            };
            this.openEditProviderDialog(provider);
            this.handleProviderList(
              this.state.currentPage,
              this.state.pageSize,
              this.state.sort
            );
          } else {
            this.setState({
              showErrorDialog: true,
              errorMessages: this.responseService.getErrorMessages(
                response.data
              ),
            });
          }
          this.setLoadingScreen(false);
        });
      this.setState({
        newProviderNameErrors: "",
        showAddProviderModal: false,
      });
    }
  };

  /**
   * Checks if provider name is valid and if not adds error to input field.
   *
   * @returns {Boolean}
   */
  isValidProviderName = (name = this.state.newProviderName) => {
    let errors = this.validatorService.validate(name, [
      new NotBlank(),
      new MinLength(3),
      new MaxLength(50),
      new Type("string"),
    ]);

    let isValid = errors.length === 0;

    if (!isValid) {
      this.setState({
        newProviderNameErrors: errors,
      });
    }

    return isValid;
  };

  /**
   * Sets state when adding new value.
   *
   * @param value
   */
  receiveValue = (value) => {
    let nameFieldErrors = this.state.newProviderNameErrors;

    this.setState({
      newProviderName: value,
      newProviderNameErrors: this.isValidProviderName(value)
        ? []
        : nameFieldErrors,
    });
  };

  /**
   * Calculates parameter object based on isChecked property.
   *
   * @param {Boolean} isChecked
   *
   * @returns {Object}
   */
  calculateParameterObject = (isChecked) => {
    let queryParameter = "";
    if (isChecked) {
      queryParameter = "enabled==" + isChecked;
    }

    if (this.state.searchProviderName !== "") {
      if (queryParameter !== "") {
        queryParameter += ",";
      }
      queryParameter += "name@=*" + this.state.searchProviderName;
    }

    let page = isChecked
      ? this.state.currentPage
      : this.state.currentPage >
        Math.ceil(this.state.totalResultsCount / this.state.pageSize)
      ? Math.ceil(this.state.totalResultsCount / this.state.pageSize)
      : this.state.currentPage;
    return {
      Filter: queryParameter ? queryParameter : null,
      Page: page,
      PageSize: this.state.pageSize,
    };
  };

  /**
   * Returns if show active providers checkbox is checked and updates state.
   *
   * @param {Boolean} isChecked
   */
  getShowActiveProvidersValue = (isChecked) => {
    let parameterObject = this.calculateParameterObject(isChecked);
    this.setLoadingScreen(true);
    this.fetchProviders(parameterObject);
    this.setState({
      showActiveProviders: isChecked,
    });
  };

  /**
   * Changes client secret and sets state accordingly.
   */
  changeClientSecret = () => {
    this.setLoadingScreen(true);
    this.providerService
      .resetClientSecret(this.state.providerData.clientId)
      .then((response) => {
        if (response.status === 200) {
          let provider = { ...this.state.providerData };
          provider["clientSecret"] = response.data.clientSecret;
          this.setState({
            providerData: provider,
            changeClientSecretConfirmationDialog: false,
          });
        } else {
          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(response.data),
          });
        }
        this.setLoadingScreen(false);
      });
  };

  /**
   * Removes provider.
   */
  deleteProvider = () => {
    this.setLoadingScreen(true);
    this.providerService
      .deleteOne(this.state.providerData.clientId)
      .then((response) => {
        if (response.status === 204) {
          let page =
            this.state.totalResultsCount % this.state.pageSize === 1
              ? this.state.currentPage - 1
              : this.state.currentPage;
          this.handleProviderList(page, this.state.pageSize, this.state.sort);
          this.setState({
            showDeleteConfirmationDialog: false,
            providerData: {},
          });
        } else {
          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(response.data),
          });
        }
        this.setLoadingScreen(false);
      });
  };

  /**
   * Returns toggle value and sets state accordingly.
   *
   * @param {Boolean} value
   */
  getToggleValue = (value) => {
    let provider = { ...this.state.providerData };

    provider["active"] = value;
    this.setState({
      providerData: provider,
    });
  };

  /**
   * Simulates click event on copy client secret button.
   */
  copyClientSecret = () => {
    let image = document.getElementById("copyClientSecret");
    image.click();
    this.setState({
      showClientSecretNotCopiedDialog: false,
    });
  };

  getIsV2Provider = (data) => {
    const value = data.target.value;

    const newV2Configuration = {
      companyId: null,
      daysAheadForBarcodesImport: null,
      daysAheadForEventsImport: null,
    };

    if (value === "V2") {
      this.setState({
        newProviderisV2SeeTicketsValue: true,
        V2Configuration:
          this.state.V2Configuration !== null
            ? this.state.V2Configuration
            : newV2Configuration,
      });
    } else {
      this.setState({
        newProviderisV2SeeTicketsValue: false,
      });
    }
  };

  getV2SettingsUpdate = (value, key) => {
    const { V2Configuration } = this.state;

    let isnum = /^\d+$/.test(value);

    isnum
      ? this.setState({
          V2Configuration: {
            ...V2Configuration,
            [key]: value,
          },
        })
      : this.setState({
          showErrorDialog: true,
          errorMessages: [
            <T _str="Please use only numbers to fill the V2 configurations" />,
          ],
        });
  };

  /**
   * Retrieves list of providers that match search value, by calling fetchProviders().
   *
   * @param searchValue
   */
  receiveSearchValue = (searchValue) => {
    let value = encodeURIComponent(searchValue);
    let queryParameter = "name@=*" + value;

    if (this.state.showActiveProviders) {
      queryParameter += ",enabled==true";
    }

    let parameterObject = {
      Filter: queryParameter,
      PageSize: this.state.pageSize,
      Page: 1,
    };

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      if (value.length > 2 || value.length === 0) {
        this.setState({
          showLoadingScreen: true,
          searchProviderName: value,
        });
        this.fetchProviders(parameterObject);
      }
    }, 300);
  };

  /**
   * Updates provider or shows Client secret not copied dialog.
   */
  editProvider = () => {
    const updateProvidersetting = () => {
      if (
        (this.state.clientCredential === "secret" && this.state.copied) ||
        !this.state.providerData.clientSecret
      ) {
        this.updateProvider();
      } else {
        this.setState({ showClientSecretNotCopiedDialog: true });
      }
    };

    let companyIdValueIsnum, showsValueIsnum, barcodesValueIsnum;

    // Check the V2 configurations only if the edit provider (newProviderisV2SeeTicketsValue) is V2 (true)
    if (this.state.newProviderisV2SeeTicketsValue) {
      companyIdValueIsnum = /^\d+$/.test(this.state.V2Configuration.companyId);
      showsValueIsnum = /^\d+$/.test(
        this.state.V2Configuration.daysAheadForEventsImport
      );
      barcodesValueIsnum = /^\d+$/.test(
        this.state.V2Configuration.daysAheadForBarcodesImport
      );
    } else {
      companyIdValueIsnum = true;
      showsValueIsnum = true;
      barcodesValueIsnum = true;
    }

    if (this.state.newProviderisV2SeeTicketsValue) {
      if (companyIdValueIsnum && showsValueIsnum && barcodesValueIsnum) {
        updateProvidersetting();
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: [
            <T _str="Please use only numbers to fill the V2 configurations" />,
          ],
        });
      }
    } else {
      updateProvidersetting();
    }
  };

  /**
   * Updates provider.
   */
  updateProvider = () => {
    const {
      newProviderisV2SeeTicketsValue,
      V2Configuration,
      providerData,
      currentPage,
      pageSize,
      sort,
    } = this.state;

    const v2Cconfigurations = newProviderisV2SeeTicketsValue
      ? { seeTicketsV2Configuration: V2Configuration }
      : {};

    let provider = {
      name: providerData.name.cellData
        ? providerData.name.cellData
        : providerData.name,
      enabled: providerData.active,
      isSeeTicketsV2: newProviderisV2SeeTicketsValue,
      ...v2Cconfigurations,
    };

    this.setLoadingScreen(true);
    this.providerService
      .updateOne(providerData.clientId, provider)
      .then((response) => {
        if (response.status === 204) {
          this.handleProviderList(currentPage, pageSize, sort);
          this.closeEditProviderModal();
        } else {
          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(response.data),
          });
        }
        this.setLoadingScreen(false);
      });
  };

  /**
   * Updates state value responsible for showing edit provider modal.
   */
  closeEditProviderModal = () => {
    this.setState({
      showEditProviderDialog: false,
      newProviderNameValue: "",
      copied: false,
      providerData: {},
      selectedProvider: {},
      isPreDefineProvider: false,
    });
  };

  /**
   * Sets provider data.
   *
   * @param {Object} providerData
   *
   * @returns {Object}
   */
  setProviderData = (providerData) => {
    return {
      name: providerData[1][0],
      shows: providerData[1][1],
      clientId: providerData[1][2],
      active: providerData[1][3].isEnable,
    };
  };

  /**
   * Updates state and opens confirmation dialog.
   *
   * @param {Object} provider
   */
  openEditProviderDialog = (provider) => {
    // Get full provider data
    const selectedProvider = this.state.providers.results.filter(
      (item) => item.name === provider.name.cellData
    )[0];

    this.setState({
      selectedProvider: selectedProvider !== undefined ? selectedProvider : {},
      V2Configuration: selectedProvider
        ? selectedProvider.seeTicketsV2Configuration
        : {},
      newProviderisV2SeeTicketsValue: selectedProvider
        ? selectedProvider.isSeeTicketsV2
        : null,
      providerData: provider,
      showEditProviderDialog: true,
    });
  };

  /**
   * Updates state and opens confirmation dialog.
   *
   * @param {Object} provider
   */
  openDeleteConfirmationDialog = (provider) => {
    this.setState({
      providerData: provider,
      showDeleteConfirmationDialog: true,
    });
  };

  /**
   * Retrieves list of providers and sets state accordingly.
   *
   * @param {Object}
   */
  fetchProviders = (parameterObject) => {
    this.providerService.getAll(parameterObject).then((response) => {
      if (response.status === 200) {
        if (
          JSON.stringify(response.data) !== JSON.stringify(this.state.providers)
        ) {
          this.setState({
            providers: response.data,
            currentPage: response.data.currentPage,
            totalPageCount: response.data.totalPageCount,
            totalResultsCount: response.data.totalResultCount,
          });
        }
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
        });
      }

      const { providerId } = this.props.match.params;
      if (providerId) {
        this.providerService.getOne(providerId).then((response) => {
          if (response.status === 200) {
            let selectedProvider = { ...response.data };
            selectedProvider.name = {
              cellData: selectedProvider.name,
              onClick: "edit",
            };
            this.setState({ isPreDefineProvider: true });
            this.openEditProviderDialog(selectedProvider);
          } else {
            this.setState({
              showErrorDialog: true,
              errorMessages: this.responseService.getErrorMessages(
                response.data
              ),
            });
          }
        });
      }

      this.setLoadingScreen(false);
    });
  };

  /**
   * Show/hide the loading screen according to the passed argument
   *
   * @param {Boolean}
   */
  setLoadingScreen = (showLoadingScreen) =>
    this.setState({ showLoadingScreen });

  /**
   * @returns {XML}
   */
  render() {
    return (
      <div className="col-12 p-0 ProviderManagement">
        {/* Renders table options area (search providers, show active providers, create new provider and refresh providers). */}
        <TableOptions
          sort={this.state.sort}
          pageSize={this.state.pageSize}
          currentPage={this.state.currentPage}
          showActiveProviders={this.state.showActiveProviders}
          receiveSearchValue={this.receiveSearchValue}
          handleProviderList={this.handleProviderList}
          getShowActiveProvidersValue={this.getShowActiveProvidersValue}
          handleAddProviderButtonClick={() =>
            this.setState({ showAddProviderModal: true })
          }
        />

        <List
          showLoadingScreen={this.state.showLoadingScreen}
          sort={this.sortColumn}
          columns="ProviderHeadRow"
          currentSorting={this.state.sort}
          data={getProviderData(this.state.providers)}
          editHandler={(tableData) =>
            this.openEditProviderDialog(this.setProviderData(tableData))
          }
          deleteHandler={(tableData) =>
            this.openDeleteConfirmationDialog(this.setProviderData(tableData))
          }
        />

        <Pagination
          changePageHandler={this.changePageHandler}
          currentPage={this.state.currentPage}
          totalPageCount={this.state.totalPageCount}
        />

        {this.state.showAddProviderModal && (
          <CreateNewProviderDialog
            showAddProviderModal={this.state.showAddProviderModal}
            newProviderNameErrors={this.state.newProviderNameErrors}
            receiveValue={this.receiveValue}
            createNewProvider={this.createNewProvider}
            closeAddProviderModal={() =>
              this.setState({
                newProviderNameErrors: "",
                newProviderName: "",
                showAddProviderModal: false,
              })
            }
          />
        )}

        {this.state.showEditProviderDialog && (
          <EditDialog
            name={this.props.name}
            copied={this.state.copied}
            providerData={this.state.providerData}
            V2Configuration={this.state.V2Configuration}
            selectedProvider={this.state.selectedProvider}
            clientCredential={this.state.clientCredential}
            newProviderNameValue={this.state.newProviderNameValue}
            showEditProviderDialog={this.state.showEditProviderDialog}
            newProviderisV2SeeTicketsValue={
              this.state.newProviderisV2SeeTicketsValue
            }
            editProvider={this.editProvider}
            getToggleValue={this.getToggleValue}
            getIsV2Provider={this.getIsV2Provider}
            getV2SettingsUpdate={this.getV2SettingsUpdate}
            onCopy={() => this.setState({ copied: true })}
            isPreDefineProvider={this.state.isPreDefineProvider}
            closeEditProviderModal={this.closeEditProviderModal}
            copyClientCredential={(clientCredential) =>
              this.setState({ clientCredential })
            }
            getProviderNameValue={(name) =>
              this.setState({
                providerData: { ...this.state.providerData, name },
              })
            }
            openChangeClientSecretConfirmationDialog={() =>
              this.setState({
                changeClientSecretConfirmationDialog: true,
                copied: false,
              })
            }
          />
        )}

        {this.state.showDeleteConfirmationDialog && (
          <ConfirmationDialog
            providerData={this.state.providerData}
            showDeleteConfirmationDialog={
              this.state.showDeleteConfirmationDialog
            }
            deleteProvider={this.deleteProvider}
            closeDeleteConfirmationDialog={() =>
              this.setState({
                showDeleteConfirmationDialog: false,
                providerData: {},
              })
            }
          />
        )}

        {/* Renders client secret not copied dialog. */}
        {this.state.showClientSecretNotCopiedDialog && (
          <RendersClientSecret
            providerData={this.state.providerData}
            showClientSecretNotCopiedDialog={
              this.state.showClientSecretNotCopiedDialog
            }
            copyClientSecret={this.copyClientSecret}
            closeClientSecretNotCopiedDialog={() =>
              this.setState({ showClientSecretNotCopiedDialog: false })
            }
          />
        )}

        {this.state.changeClientSecretConfirmationDialog && (
          <ChangeClientSecretConfirmationDialog
            providerData={this.state.providerData}
            changeClientSecretConfirmationDialog={
              this.state.changeClientSecretConfirmationDialog
            }
            changeClientSecret={this.changeClientSecret}
            closeChangeClientSecretConfirmationDialog={() =>
              this.setState({ changeClientSecretConfirmationDialog: false })
            }
          />
        )}

        {this.state.showLoadingScreen && <LoadingScreen />}

        <DialogError
          show={this.state.showErrorDialog}
          closeDialog={() => this.setState({ showErrorDialog: false })}
        >
          {this.state.errorMessages.map((message, index) => (
            <p key={index}>{message}</p>
          ))}
        </DialogError>
      </div>
    );
  }
}

const CreateNewProviderDialog = ({
  showAddProviderModal,
  newProviderNameErrors,
  createNewProvider,
  closeAddProviderModal,
  receiveValue,
}) => (
  <Dialog
    title={<T _str="Create new provider" />}
    showModal={showAddProviderModal}
    action={() => createNewProvider()}
    closeModal={closeAddProviderModal}
    secondaryButton={<T _str="cancel" />}
    mainButton={<T _str="create" />}
  >
    <Input
      name="Provider name"
      label={<T _str="Provider name" />}
      getValue={receiveValue}
      className={newProviderNameErrors.length > 0 ? "error" : ""}
      focus
    />
    {newProviderNameErrors.length > 0 &&
      newProviderNameErrors.map((error, key) => (
        <div key={key} className="error">
          - {error}
        </div>
      ))}
  </Dialog>
);

const ChangeClientSecretConfirmationDialog = ({
  changeClientSecretConfirmationDialog,
  providerData,
  changeClientSecret,
  closeChangeClientSecretConfirmationDialog,
}) => (
  <Dialog
    mainButton={<T _str="replace" />}
    title={<T _str="Confirmation dialog" />}
    showModal={changeClientSecretConfirmationDialog}
    closeModal={closeChangeClientSecretConfirmationDialog}
    action={changeClientSecret}
    secondaryButton={<T _str="cancel" />}
  >
    <h4 className="text-center">
      <T _str="Change Providers Client Secret" />
    </h4>
    <div className="row">
      <div className="col-lg-1">&nbsp;</div>
    </div>
    <div className="row">
      <div className="col-lg-3">
        <T _str="Name" />
      </div>
      <div className="col-lg-1">&nbsp;</div>
      <div className="col-lg-8">{providerData.name.cellData}</div>
    </div>
    <div className="row">
      <div className="col-lg-3">
        <T _str="Client Id" />
      </div>
      <div className="col-lg-1">&nbsp;</div>
      <div className="col-lg-8">{providerData.clientId}</div>
    </div>
    <div className="row">
      <div className="col-lg-3">
        <T _str="Client Secret" />
      </div>
      <div className="col-lg-1">&nbsp;</div>
      <div className="col-lg-8 red-text-warning">
        <T _str="Replacing the client secret can not be undone. If you proceed, you need to copy the new" />
        <T _str="secret and insert it the provider system." />
      </div>
    </div>
  </Dialog>
);

const RendersClientSecret = ({
  showClientSecretNotCopiedDialog,
  closeClientSecretNotCopiedDialog,
  providerData,
  copyClientSecret,
}) => (
  <Dialog
    mainButton={<T _str="Copy and close" />}
    title={<T _str="Client secret not yet copied" />}
    action={copyClientSecret}
    hideSecondaryButton={true}
    closeModal={closeClientSecretNotCopiedDialog}
    showModal={showClientSecretNotCopiedDialog}
  >
    <div className="row">
      <div className="col-lg-1">&nbsp;</div>
    </div>
    <div className="row">
      <div className="col-lg-3">
        <T _str="Name" />
      </div>
      <div className="col-lg-1">&nbsp;</div>
      <div className="col-lg-8">{providerData.name.cellData}</div>
    </div>
    <div className="row">
      <div className="col-lg-3">
        <T _str="Client Id" />
      </div>
      <div className="col-lg-1">&nbsp;</div>
      <div className="col-lg-8">{providerData.clientId}</div>
    </div>
    <div className="row">
      <div className="col-lg-3">
        <T _str="Client Secret" />
      </div>
      <div className="col-lg-1">&nbsp;</div>
      <div className="col-lg-8 red-text-warning">
        <T _str="You replaced the client secret and did not copy it to the clipboard yet." />
        <T _str="You need to copy the new secret and insert it in the provider system." />
      </div>
    </div>
  </Dialog>
);

const TableOptions = ({
  receiveSearchValue,
  getShowActiveProvidersValue,
  handleAddProviderButtonClick,
  handleProviderList,
  showActiveProviders,
  currentPage,
  pageSize,
  sort,
}) => {
  const t = useT();
  document.title = "Starticket - " + t("Providers");
  return (
    <div className="row p-0 m-0 mb-4 table-options">
      <div className="col-md-3 p-0">
        <Search
          name={<T _str="search" />}
          placeholder="Search"
          getValue={receiveSearchValue}
        />
      </div>
      <div className="col-md-9 p-0">
        <div className="row m-0">
          <div className="col-md-6 p-0 active-providers">
            <Toggle
              label={<T _str="Show active providers only" />}
              isChecked={showActiveProviders}
              callBack={getShowActiveProvidersValue}
            />
          </div>
          <div className="col-md-6 text-end p-0">
            <Button
              className={ButtonAdd}
              onClickHandler={handleAddProviderButtonClick}
            >
              <T _str="Create new" />
            </Button>

            <RefreshButton
              className={ButtonSize.Tiny}
              onClickHandler={() =>
                handleProviderList(currentPage, pageSize, sort)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Renders list head row.
 *
 * @param {Object} columns
 *
 * @returns {any[]}
 */
export const ProviderHeadRow = ({ sort, currentSorting }) => {
  const listServices = new ListService();

  const t = useT();

  const provider = t("Provider");
  const number = t("Number of shows");
  const client = t("Client Id");
  const active = t("Active");

  const providerColumns = {
    [provider]: { subtitle: null, sortable: true, fieldValue: "name" },
    [number]: {
      subtitle: null,
      sortable: false,
      fieldValue: "shows",
      className: "shows-number",
    },
    [client]: { subtitle: null, sortable: false, fieldValue: "name" },
    [active]: {
      subtitle: null,
      sortable: true,
      fieldValue: "enabled",
      className: "active-column",
    },
    "": {
      subtitle: null,
      sortable: false,
      fieldValue: "enabled",
      className: "provider-buttons",
    },
  };

  return (
    <tr>
      {Object.entries(providerColumns).map((column, index) => (
        <th
          className={column[1].className ? column[1].className : ""}
          key={index}
        >
          {column[0]}
          {column[1].subtitle !== null && (
            <span className="grayed">{column[1].subtitle}</span>
          )}
          {column[1].sortable && (
            <SortingArrowsIcon
              className={`pull-right sorting-arrow ${listServices.sortingArrowClassController(
                column,
                currentSorting
              )}`}
              onClick={() => sort(column[1].fieldValue)}
            />
          )}
        </th>
      ))}
    </tr>
  );
};

/**
 * Returns adapted data for provider list.
 *
 * @param {Object} responseData
 *
 * @returns {Object}
 */
const getProviderData = (responseData) => {
  let tableData = {};

  responseData["results"] &&
    responseData["results"].map((provider) => {
      let providerData = [];

      providerData.push({
        cellData: provider.name,
        onClick: "edit",
      });

      providerData.push(provider.shows);
      providerData.push(provider.clientId);

      let activeColumn = {
        cellData: provider.enabled ? CheckIcon() : CloseIcon(),
        className: provider.enabled ? "Active provider" : "Inactive provider",
        isImage: true,
        isEnable: provider.enabled,
      };
      providerData.push(activeColumn);

      const iconsColumn = {
        cellOptions: {
          delete: true,
        },
      };

      providerData.push(iconsColumn);
      tableData[provider.clientId] = providerData;

      return true;
    });

  return tableData;
};

export default ProviderManagement;
